
export interface Marcas {
  id?: number;
  oleos: any[];
  filtroAr: any[];
  filtroCabine: any[];
  filtroCombustivel: any[];
  filtroOleo: any[];
  pneus: any[];
}

import { onBeforeUnmount, onMounted, Ref, ref, watch } from "vue";
import MarcaPadraoModal from "./MarcaPadraoModal.vue";
import Button from "@/components/Button.vue";
import useEmitter from "@/composables/Emmiter";
import useAlert from "@/composables/Alert";
import Api from "@/services/Api";

export default {
  components: {
    MarcaPadraoModal,
    Button,
  },
  name: "MarcaPadrao",
  setup() {
    const dialogWidth: Ref<string> = ref("80%");
    const emitter = useEmitter();
    const { showTimeAlert } = useAlert();
    const modalEdicaoOnOff: Ref<boolean> = ref(false);
    const modalTitle: Ref<string> = ref("");
    const propDragDrop: Ref<any> = ref([]);
    const marcasParceiras: Ref<Marcas> = ref({
      id: 0,
      oleos: [],
      filtroAr: [],
      filtroCabine: [],
      filtroCombustivel: [],
      filtroOleo: [],
      pneus: [],
    });

    const marcasParceirasTotal: Ref<Marcas> = ref({
      id: 0,
      oleos: [],
      filtroAr: [],
      filtroCabine: [],
      filtroCombustivel: [],
      filtroOleo: [],
      pneus: [],
    });

    function sendArrayCorrectly(name: string) {
      propDragDrop.value = [];
      try {
        const { id } = marcasParceiras.value;
        const match = Object.keys(marcasParceiras.value).find(item => item === name);
        propDragDrop.value = marcasParceiras.value[match as any].map((item: any) => ({ ...item, id, match }));
      } catch (error) {
        console.log(error);
      } finally {
        modalEdicaoOnOff.value = true;
      }
    }

    const closeModal = () => {
      modalEdicaoOnOff.value = false;
    };

    async function getAllParceiros() {
      const res = await Api.get(`getAllParceiros`);
      const { oleos, filtroAr, filtroCabine, filtroCombustivel, filtroOleo, pneus } = res;
    }

    emitter.on("GetDadosLoja", async params => {
      if (!params.DadosLoja[1].filtroOleo.length) {
        return;
      }
      marcasParceiras.value = params.DadosLoja[1];
      marcasParceirasTotal.value = {
        id: 0,
        oleos: marcasParceiras.value.oleos.filter(item => item.ativo === "sim"),
        filtroAr: marcasParceiras.value.filtroAr.filter(item => item.ativo === "sim"),
        filtroCabine: marcasParceiras.value.filtroCabine.filter(item => item.ativo === "sim"),
        filtroCombustivel: marcasParceiras.value.filtroCombustivel.filter(item => item.ativo === "sim"),
        filtroOleo: marcasParceiras.value.filtroOleo.filter(item => item.ativo === "sim"),
        pneus: marcasParceiras.value.pneus.filter(item => item.ativo === "sim"),
      };
    });

    function attSelecionados(arr, str) {
      const keys = Object.keys(marcasParceirasTotal.value);
      const myKeyIndex = keys.indexOf(str);
      if (myKeyIndex !== -1) {
        const newKeyObj = { [str]: arr.filter(item => item.ativo === "sim") };
        const newMarcasParceirasTotal = { ...marcasParceirasTotal.value, ...newKeyObj };
        marcasParceirasTotal.value = newMarcasParceirasTotal;
      }
    }

    function atualizarListaReordenada(arr: any, str: any) {
      try {
        marcasParceiras.value[str] = arr;
      } catch (error) {
        console.log(error);
      } finally {
        modalEdicaoOnOff.value = false;
      }
    }

    const setModalTitle = (evt: any) => {
      let title = evt.target.dataset.title;

      if (typeof title === "string" && title.trim() !== "") {
        modalTitle.value = title.trim();
      }
    };

    async function enviarEmit() {
      await emitter.emit("GetMarcaPadraoAtualizados", {
        MarcaPadraoAtualizados: [marcasParceiras.value],
      });
    }

    async function getParceiros() {
      const res = await Api.get(`getAllParceiros`);
      const { oleo, filtroAr, filtroAc, filtroCombustivel, filtroOleo, pneu } = res;
      if (!marcasParceiras.value.filtroOleo.length) {
        marcasParceiras.value = {
          id: 0,
          oleos: oleo,
          filtroAr: filtroAr,
          filtroCabine: filtroAc,
          filtroCombustivel: filtroCombustivel,
          filtroOleo: filtroOleo,
          pneus: pneu,
        };
        return;
      }
      return;
    }

    function updateDialogWidth() {
      if (window.innerWidth >= 1200) {
        dialogWidth.value = "50%";
      } else {
        dialogWidth.value = "90%";
      }
    }

    onMounted(() => getParceiros());
    onMounted(() => {
      updateDialogWidth();
      window.addEventListener("resize", updateDialogWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateDialogWidth);
    });

    watch(
      () => marcasParceiras.value,
      () => enviarEmit(),
      { deep: true }
    );

    watch(
      () => modalEdicaoOnOff.value,
      () =>
        (marcasParceirasTotal.value = {
          id: 0,
          oleos: marcasParceiras.value.oleos.filter(item => item.ativo === "sim"),
          filtroAr: marcasParceiras.value.filtroAr.filter(item => item.ativo === "sim"),
          filtroCabine: marcasParceiras.value.filtroCabine.filter(item => item.ativo === "sim"),
          filtroCombustivel: marcasParceiras.value.filtroCombustivel.filter(item => item.ativo === "sim"),
          filtroOleo: marcasParceiras.value.filtroOleo.filter(item => item.ativo === "sim"),
          pneus: marcasParceiras.value.pneus.filter(item => item.ativo === "sim"),
        })
    );

    return {
      modalEdicaoOnOff,
      modalTitle,
      closeModal,
      setModalTitle,
      marcasParceirasTotal,
      marcasParceiras,
      attSelecionados,
      sendArrayCorrectly,
      propDragDrop,
      atualizarListaReordenada,
      dialogWidth,
    };
  },
};
