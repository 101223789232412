
import useEmitter from "@/composables/Emmiter";
import Api from "@/services/Api";
import { DefineComponent, defineComponent, onMounted, ref, Ref, watch } from "vue";

interface ConfigLoja {
  dadosExtras: {
    geral: any;
    credenciais: any;
  };
}

export default defineComponent({
  components: {},
  name: "DadosExtrasIpiranga",
  emits: ["fecharModal"],
  props: {},
  setup() {
    const emitter = useEmitter();
    const keysObjGeral: Ref<any> = ref([]);
    const keysObjCredencias: Ref<any> = ref([]);
    const exibirIntegracao: Ref<boolean> = ref(false);
    const dadosExtraRef: Ref<ConfigLoja> = ref({
      dadosExtras: {
        geral: null,
        credenciais: null,
      },
    });

    async function enviarEmit() {
      await emitter.emit("GetDadosExtrasAtualizados", {
        DadosExtrasAtualizados: [dadosExtraRef.value],
      });
    }

    emitter.on("GetDadosLoja", async params => {
      dadosExtraRef.value = params.DadosLoja[4];

      if (dadosExtraRef.value && dadosExtraRef.value.dadosExtras && dadosExtraRef.value.dadosExtras.geral) {
        keysObjGeral.value = Object.keys(dadosExtraRef.value.dadosExtras.geral);
      }

      if (dadosExtraRef.value && dadosExtraRef.value.dadosExtras && dadosExtraRef.value.dadosExtras.credenciais) {
        keysObjCredencias.value = Object.keys(dadosExtraRef.value.dadosExtras.credenciais);
      }
    });


    emitter.on("exibir", async params => {
      exibirIntegracao.value = params.integracao;
    })

    
    watch(
      () => dadosExtraRef.value,
      () => enviarEmit(),

      { deep: true }
    );



    return {
      keysObjGeral,
      dadosExtraRef,
      keysObjCredencias,
      exibirIntegracao,
    };
  },
});
