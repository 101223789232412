
interface DadosLoja {
  id: number;
  CNPJ: string;
  telefone: string;
  responsavel: string;
  nomefantasia: string;
  razaoSocial: string;
  endereco: string;
  email: string;
  created_at: string
}

import useEmitter from "@/composables/Emmiter";
import { defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useLoaderStore } from "@/store/LoaderStore";
import { useOpenCreateStore } from "@/store/OpenCreateStore";
import { useGetIntegracaoParceirosStore } from "@/store/GetIntegracaoParceirosStore";
import Api from "@/services/Api";

export default defineComponent({
  name: "DadosDaLoja",
  emits: [],
  props: {},
  setup() {
    const emitter = useEmitter();
    const openCreateStore = useOpenCreateStore();
    const getIntegracaoParceirosStore = useGetIntegracaoParceirosStore();
    const loaderStore = useLoaderStore();
    const storeGroups: Ref<any[]> = ref([]);
    const groupSelected: Ref<any> = ref("");
    const cnpjOk: Ref<boolean> = ref(false);
    const loading: Ref<boolean> = ref(false);
    const cnpjFind: Ref<string> = ref('');
    
    const dadosLoja: Ref<DadosLoja> = ref({
      id: 0,
      CNPJ: "",
      telefone: "",
      responsavel: "",
      nomefantasia: "",
      razaoSocial: "",
      endereco: "",
      email: "",
      created_at: ""
    });

    async function getCnpjOk() {
      let cnpj_sem_pontuacao = dadosLoja.value.CNPJ.replace(/[^\d]/g, "");
      // console.log(cnpjFind.value);
      
      if (cnpj_sem_pontuacao.length === 14 && cnpj_sem_pontuacao !== cnpjFind.value) {
        const res = await Api.post("lojas/buscarCnpjExiste", { cnpj: cnpj_sem_pontuacao });
        cnpjOk.value = res.cnpjExiste;
      }
      
      if (!cnpj_sem_pontuacao) {
        cnpjOk.value = false;
      }
      
      cnpjFind.value = cnpj_sem_pontuacao.length === 14 ? cnpj_sem_pontuacao : cnpj_sem_pontuacao.substring(0, cnpj_sem_pontuacao.length -1);
    }

    emitter.on("GetDadosLoja", async params => {
      dadosLoja.value = params.DadosLoja[0];
      if(dadosLoja.value.CNPJ === " ") {dadosLoja.value.CNPJ = ''}
      loading.value = false
    });

    async function enviarEmit() {
      await emitter.emit("GetDadosLojaAtualizados", {
        DadosLojaAtualizados: [dadosLoja.value],
      });
    }

    async function getStoreGroups() {
      const { data } = await Api.get("getAllStoreGroup");
      storeGroups.value = data.grupos;
    }

    function buscarIntegracao(param: any) {
      getIntegracaoParceirosStore.open(`${groupSelected.value}, ${param}`);
    }

    onMounted(() => {
      getStoreGroups(), 
      groupSelected.value = ''
    });

    watch(
      () => groupSelected.value,
      () => {loading.value = true, buscarIntegracao("")}
    );

    watch(
      () => dadosLoja.value,
      () => enviarEmit(),
      { deep: true }
    );

    return {
      dadosLoja,
      openCreateStore,
      storeGroups,
      groupSelected,
      getCnpjOk,
      cnpjOk,
      buscarIntegracao,
      loading
    };
  },
});
