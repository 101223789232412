import { defineStore } from "pinia";
export const useOpenCreateStore = defineStore("OpenCreateStore", {

  state: () => {
    return {
      openCreateStore: false
    }
  },

  actions: {
    open(): void {
      this.openCreateStore = true
    },
    close(): void {
      this.openCreateStore = false
    }
  },

  getters: {
    status(): boolean {
      return this.openCreateStore
    }
  }
});






