import { defineStore } from "pinia";
export const useGetIntegracaoParceirosStore = defineStore("GetIntegracaoParceirosStore", {

  state: () => {
    return {
      getIntegracaoParceirosStore: ''
    }
  },

  actions: {
    open(str :string): void {
      this.getIntegracaoParceirosStore = str
    },
    close(str :string): void {
      this.getIntegracaoParceirosStore = str
    },
    clear(): void {
      this.getIntegracaoParceirosStore = '';
    }
  },

  getters: {
    status(): string {
      return this.getIntegracaoParceirosStore;
    }
  }
});






